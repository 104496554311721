/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useState } from 'react';
import Layout from '../../components/Layout';

export const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); // Prevent the default form submission
        setIsLoading(true);

        try {
            await axios.post(
                'https://xpvws5nnpzdnw7twcqntxllw4q0meryj.lambda-url.us-east-1.on.aws/',
                formData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setIsSubmitted(true); // Mark as submitted on success
        } catch (error: any) {
            console.error(
                error.response?.data?.error || 'Failed to send your message.'
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Layout>
            <div
                style={{ maxWidth: '600px', margin: '0 auto', padding: '1rem' }}
            >
                {isSubmitted ? (
                    <div>
                        <h2>Thank You!</h2>
                        <p>
                            Your message has been sent successfully. I will get
                            back to you as soon as possible.
                        </p>
                    </div>
                ) : (
                    <>
                        <h2>Get in Touch</h2>
                        <p>
                            I am currently accepting select commissions for
                            design, portraits, paintings, and murals. Let’s
                            create something meaningful together. Fill out the
                            form below to get started!
                        </p>
                        <form
                            onSubmit={handleSubmit}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem',
                            }}
                        >
                            <input
                                type="text"
                                name="name"
                                placeholder="Your Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                disabled={isLoading}
                                style={{
                                    padding: '0.5rem',
                                    fontSize: '1rem',
                                    backgroundColor: isLoading
                                        ? '#f5f5f5'
                                        : 'white',
                                }}
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Your Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                disabled={isLoading}
                                style={{
                                    padding: '0.5rem',
                                    fontSize: '1rem',
                                    backgroundColor: isLoading
                                        ? '#f5f5f5'
                                        : 'white',
                                }}
                            />
                            <input
                                type="text"
                                name="subject"
                                placeholder="Subject"
                                value={formData.subject}
                                onChange={handleChange}
                                required
                                disabled={isLoading}
                                style={{
                                    padding: '0.5rem',
                                    fontSize: '1rem',
                                    backgroundColor: isLoading
                                        ? '#f5f5f5'
                                        : 'white',
                                }}
                            />
                            <textarea
                                name="message"
                                placeholder="Message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                                disabled={isLoading}
                                style={{
                                    padding: '0.5rem',
                                    fontSize: '1rem',
                                    minHeight: '100px',
                                    backgroundColor: isLoading
                                        ? '#f5f5f5'
                                        : 'white',
                                }}
                            />
                            <button
                                type="submit"
                                style={{
                                    display: 'block',
                                    backgroundColor: '#fca903',
                                    color: 'white',
                                    padding: '0.75rem',
                                    fontSize: '1.5rem',
                                    border: 'none',
                                    borderRadius: '4px',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    fontVariant: 'small-caps',
                                    cursor: isLoading
                                        ? 'not-allowed'
                                        : 'pointer',
                                }}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Sending...' : 'Send'}
                            </button>
                        </form>
                    </>
                )}
            </div>
        </Layout>
    );
};

export default Contact;
