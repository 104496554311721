import React from 'react';
import Home from './pages/Home';
import Artwork from './pages/Artwork';
import About from './pages/About';
import Contact from './pages/Contact';
import Design from './pages/design';
import Commissions from './pages/Commissions';
import { Route } from 'react-router';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.module.scss';

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/about" component={About} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/commissions" component={Commissions} />
                <Route exact path="/design" component={Design} />
                <Route path="/:id" component={Artwork} />
            </Switch>
        </Router>
    );
}

export default App;
