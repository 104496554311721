import React from 'react';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { Logo } from '../../components/Logo';
import classNames from 'classnames';

const socialMediaIconSize = 29;
export const Footer = ({ className }: { className?: string }) => {
    return (
        <>
            <center>
                <div>
                    <div className={styles.subtitle}>Follow me:</div>
                    <div className={styles.links}>
                        <a href="https://www.instagram.com/seekingsomaart">
                            <img
                                alt="instagram"
                                src={`/images/instagram-${socialMediaIconSize}.png`}
                            />
                        </a>{' '}
                        <a href="https://www.facebook.com/seekingsomaart">
                            <img
                                alt="facebook"
                                src={`/images/facebook-${socialMediaIconSize}.png`}
                            />
                        </a>
                    </div>
                </div>
                <div className={styles.copyright}>
                    Copyright {new Date().getFullYear()} Seeking Soma Art LLC.{' '}
                    <br />
                    All rights reserved.
                </div>
            </center>
        </>
    );
};
export function Layout({
    children,
    showNav = true,
}: {
    children: any;
    showNav?: boolean;
}) {
    return (
        <div className={styles.layout}>
            <div className={styles.header}>
                <Logo className={styles.logo} />
                {showNav && (
                    <ul className={styles.nav}>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/about">About</Link>
                        </li>
                        <li>
                            <Link to="/commissions">Commissions</Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact</Link>
                        </li>
                    </ul>
                )}
            </div>
            <div className={styles.content}>{children}</div>
            <Footer className={styles.footer} />
        </div>
    );
}

export default Layout;
