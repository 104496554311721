/* eslint-disable react-hooks/exhaustive-deps */
// import { useContext } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout';
// import { useThrivingArtistContext } from '../../../../junk/Provider';
import styles from './index.module.scss';
// import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const Commissions = () => {
    //const bob = useThrivingArtistContext();
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <Layout>
            <div className={styles.container}>
                <h1>Commissions</h1>
                <h2>Holiday Portriat Sale 2024</h2>
                <div className={styles.slider}>
                    <div className={styles.slide}>
                        <img
                            alt="Kilton"
                            src="/images/commissions/kilton.png"
                        />
                    </div>
                    <div className={styles.slide}>
                        <img alt="Laney" src="/images/commissions/laney.jpg" />
                    </div>
                    <div className={styles.slide}>
                        <img alt="Kitty" src="/images/commissions/kitty.png" />
                    </div>
                    <div className={styles.slide}>
                        <img
                            alt="Alan Wallace Portrait"
                            src="/images/commissions/alan-wallace.jpg"
                        />
                    </div>
                    <div className={styles.slide}>
                        <img
                            alt="Rocket Portrait"
                            src="/images/commissions/rocket.png"
                        />
                    </div>
                    <div className={styles.slide}>
                        <img
                            alt="Curly Fries Portrait"
                            src="/images/commissions/curly-fries.jpg"
                        />
                    </div>
                </div>
                <p>
                    Celebrate the holidays with a timeless portrait of your
                    loved ones—furry or human. Whether you're looking for a
                    striking statement piece or a more intimate keepsake, I
                    offer portraits in a range of sizes to suit any space. Each
                    piece is painted by hand in oils on wood panel and crafted
                    with care, capturing the essence of personality and
                    connection. With fixed pricing, the process is simple and
                    transparent, so you can focus on choosing the perfect size
                    for your home. Let’s create something truly memorable this
                    holiday season.
                </p>
                <p>
                    Creating your custom portrait is simple and personal. Book
                    your spot, provide reference photos, and choose the size for
                    your piece. The first two clients to book before December
                    10th are guaranteed delivery by December 24th; all others
                    will be completed within 90 days of payment. This
                    Thanksgiving sale runs through New Year’s Eve 2024. Single
                    subject portraits only.
                </p>
                <div className={styles.pricingTable}>
                    <h4>Portrait pricing</h4>
                    <table
                        style={{
                            width: '100%',
                            margin: 'auto',
                        }}
                    >
                        <tr>
                            <th>Size</th>
                            <th>Normal price</th>
                            <th>Thanksgiving Sale</th>
                        </tr>
                        <tr>
                            <td>18x24</td>
                            <td>$2000</td>
                            <td>$1350</td>
                        </tr>
                        <tr>
                            <td>16x20</td>
                            <td>$1500</td>
                            <td>$1000</td>
                        </tr>
                        <tr>
                            <td>12x16</td>
                            <td>$1000</td>
                            <td>$650</td>
                        </tr>
                        <tr>
                            <td>12x12</td>
                            <td>$750</td>
                            <td>$500</td>
                        </tr>
                    </table>

                    <a
                        href="https://shop.seekingsomaart.com/product/holiday-sale-portraits"
                        style={{
                            marginTop: '20px',
                            display: 'block',
                            backgroundColor: '#fca903',
                            color: 'white',
                            padding: '0.75rem',
                            fontSize: '1.5rem',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontVariant: 'small-caps',
                        }}
                    >
                        Shop now!
                    </a>
                </div>
                <hr></hr>
                <h2>Other work</h2>
                <p>
                    I am open to select commissions for fine art, design, and
                    illustration projects, depending on the nature of the work.
                    I prioritize projects that align with my artistic vision or
                    those that inspire me to bring them to life.
                </p>
                <p>
                    If you’re interested in commissioning a piece or discussing
                    a project, I’d love to hear from you.
                    <Link to="/contact">
                        Click here to fill out the contact form
                    </Link>{' '}
                    and let’s start creating something meaningful together.
                </p>
            </div>
        </Layout>
    );
};

export default Commissions;
