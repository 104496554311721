/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Layout from '../../components/Layout';
import { Artwork } from '../../client';
// nimport { groupBy } from 'ramda';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import MediaImage, {
    MediaImageFit,
    SizesEnum,
} from '../../components/MediaImage';
import { useArtworkList } from '../../client';
import { orderBy } from 'lodash';

export const Thumbnail = ({ artwork }: { artwork: Artwork }) => {
    return (
        <Link to={`/${artwork.id}`} className={styles.thumbnail}>
            <MediaImage
                artwork={artwork}
                fit={MediaImageFit.COVER}
                size={SizesEnum.Medium}
                width={500}
                height={500}
                jpgQuality={50}
                className={styles.mediaImage}
            ></MediaImage>
            <h3 className={styles.title}>{artwork.title}</h3>
        </Link>
    );
};

export const ArtworkGroup = ({ data }: { data: Artwork[] }) => {
    return (
        <div>
            <div className={styles.artworkGroupContainer}>
                {orderBy(data, ['year'], ['desc']).map((artwork) => (
                    <Thumbnail artwork={artwork} />
                ))}
            </div>
        </div>
    );
};

export const Home = () => {
    const [artworks] = useArtworkList();
    // let children: JSX.Element[] = [];

    // if (loaded && artworks) {
    //     // const groupedArtworks = Object.entries(
    //     //     groupBy((artwork) => artwork.year, artworks)
    //     // );
    //     children = artworks.map((group) => {
    //         const name = group[0] as String;
    //         const data = group[1] as Artwork[];
    //         return;
    //     });
    // }

    return (
        <Layout>
            <ArtworkGroup data={artworks} />
        </Layout>
    );
};

export default Home;
